import React from 'react';
import './LongLetFees.css'; // Import your CSS file for buildings styling

const LongLetFees = () => {
    return (
        <section id="longletfees-section" className="my-20 md:my-36">
            <div className="container">
                <h4>An overview of our services for your complete peace of mind.</h4>
                <h3>Management service</h3>

                <div id="tables-container">
                    <table id="table1">
                        <thead>
                            <tr>
                                <th className='main'>
                                    <label>Lettings only</label>
                                </th>
                                <th><label>Lettings Only</label> <br /><span>10%+vat</span></th>
                                <th><label>Lettings Only</label> <br /><span>13% +vat</span></th>
                                <th><label>Lettings Only</label><br /><span>15% +vat</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Marketing and advertising to find the right tenant</td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                            </tr>

                            {/* line2 */}
                            <tr>
                                <td>Accompanied viewings</td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                            </tr>

                            {/* line3 */}
                            <tr>
                                <td>Tenant reference checks</td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                            </tr>

                            {/* line4 */}
                            <tr>
                                <td>Creation of legally binding tenancy agreement</td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                            </tr>

                            {/* line5 */}
                            <tr>
                                <td>Right to Rent checks</td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table id="table2">
                        <thead>
                            <tr>
                                <th className='main'>
                                    <label>Full Management</label>
                                </th>
                                <th className='white-text'>Lettings Only  </th>
                                <th className='white-text'>Lettings Only  </th>
                                <th className='white-text'>Lettings Only  </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> Utility conversion</td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                            </tr>

                            {/* line2 */}
                            <tr>
                                <td> Deposit protection</td>
                                <td>

                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                            </tr>

                            {/* line3 */}
                            <tr>
                                <td> Arranging Inventory & Checkout reports</td>
                                <td>

                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                            </tr>

                            {/* line4 */}
                            <tr>
                                <td> Collection of rent</td>
                                <td>

                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                            </tr>

                            {/* line5 */}
                            <tr>
                                <td> Comprehensive credit control</td>
                                <td>

                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                            </tr>

                            {/* line6 */}
                            <tr>
                                <td>  Deposit claim negotiation</td>
                                <td>

                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                            </tr>

                            {/* line7 */}
                            <tr>
                                <td>   Dedicated Property Management team</td>
                                <td>

                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                            </tr>

                            {/* line8 */}
                            <tr>
                                <td>    Keyholding service</td>
                                <td>

                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                            </tr>

                            {/* line9 */}
                            <tr>
                                <td>     Arranging quotes, repairs, maintenance</td>
                                <td>

                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                            </tr>

                            {/* line10 */}
                            <tr>
                                <td>      Access to a network of pre-approved contractors</td>
                                <td>

                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                            </tr>

                            {/* line11 */}
                            <tr>
                                <td>       24-hour emergency contractors</td>
                                <td>

                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                            </tr>

                            {/* line12 */}
                            <tr>
                                <td>        Annual property inspection</td>
                                <td>

                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                            </tr>

                            {/* line13 */}
                            <tr>
                                <td>         Handlling the check-out process</td>
                                <td>

                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                            </tr>

                            {/* line14 */}
                            <tr>
                                <td>          Assist with legal notices eg: S.21, S.8, S.13</td>
                                <td>

                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                            </tr>

                        </tbody>
                    </table>

                    <table id="table3">
                        <thead>
                            <tr>
                                <th className='main'>
                                    <label>Enhanced Management</label>
                                </th>
                                <th className='white-text'>Lettings Only  </th>
                                <th className='white-text'>Lettings Only  </th>
                                <th className='white-text'>Lettings Only  </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>  Six monthly Inspections</td>
                                <td>

                                </td>
                                <td>

                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                            </tr>

                            {/* line2 */}
                            <tr>
                                <td>   Rent Protection & Legal Expenses Insurance</td>
                                <td>

                                </td>
                                <td>

                                </td>
                                <td className="icon-container">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" stroke-width="3" />
                                        <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="white" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                <div id="additional">
                    <span>Additional charges include</span>
                    <ul>
                        <li>Inventory Report</li>
                        <li>Checkout Report</li>
                        <li>Cleaning</li>
                        <li>Legal Notice Fee</li>
                        <li>Renewed / New Company</li>
                    </ul>
                </div>

            </div>
        </section>
    );
};

export default LongLetFees;
